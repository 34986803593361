import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import LandingPage from './Pages/LandingPage';
import TravelCalculator from './Pages/TravelCalculator';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageLayout from './Components/PageLayout';
import Wiki from './Wiki/Wiki';
import Construction from './Pages/Construction';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<PageLayout />} >         
          <Route index element={<Construction />} />
        </Route>
      </Routes>
    </>
  );
}


export default App;
