import { Container, Image } from "react-bootstrap";

const Construction = () => {
    return (
        <>            
            <Container>
                <Image src="./construction.png">

                </Image>
                <h2>
                    Estamos trabalhando em uma nova Página para lhe atender melhor!
                </h2>
                <h3>Por enquannto, você pode consultar nossos serviços nos links:
                <ul>
                    <li>
                        <a href="https://premium.europatinhas.com/">Plano Premium - Seu Pet viaja conosco</a>
                    </li>
                    <li>
                        <a href="https://standard.europatinhas.com/">Plano Standard - Assessoria para você viajar com seu pet</a>
                    </li>
                </ul>
                </h3>
            </Container>
        </>
    );
};

export default Construction;